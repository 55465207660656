export default {
  methods: {
    async tipoCambioQuery() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: require("@/graphql/queries/tipoCambio.gql"),
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.$store.state.isLoading = false;
          this.tasadia = data.data.tipoCambioConsulta.data[0].tipo_cambio;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
    async rubroTotalQuery() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: require("@/graphql/queries/rubrosTotal.gql"),
          variables: {
            filtro: "rubro"
          }
        })
        .then(data => {
          this.$store.state.isLoading = false;
          this.rubrosTotal = data.data.rubrosTotal;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    }
  },
  computed: {
    opcionesRubro() {
      let llenarRubros = [];
      for (let i = 0; i < this.rubrosTotal.length; i++) {
        llenarRubros.push(this.rubrosTotal[i].rubro);
      }
      return llenarRubros;
    }
  }
};
